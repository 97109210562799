import { Injectable } from '@angular/core';
import to from 'await-to-js';
import { ServerConnectionService } from './server-connection.service';
import { CoreModule } from '../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class AgentService {
	constructor(
		private scs: ServerConnectionService,
	) { }

	getBrowserInfo(): string {
		const { userAgent } = window.navigator;
		let browserName = 'Unknown';
		let browserVersion = '';

		if (userAgent.includes('Firefox')) {
			browserName = 'Mozilla Firefox';
			browserVersion = userAgent.match(/Firefox\/([\d.]+)/)?.[1] || '';
		} else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
			browserName = 'Opera';
			browserVersion = userAgent.match(/(Opera|OPR)\/([\d.]+)/)?.[2] || '';
		} else if (userAgent.includes('Trident')) {
			browserName = 'Microsoft Internet Explorer';
			browserVersion = userAgent.match(/rv:([\d.]+)/)?.[1] || '';
		} else if (userAgent.includes('Edge')) {
			browserName = 'Microsoft Edge';
			browserVersion = userAgent.match(/Edge\/([\d.]+)/)?.[1] || '';
		} else if (userAgent.includes('Chrome')) {
			browserName = 'Google Chrome';
			browserVersion = userAgent.match(/Chrome\/([\d.]+)/)?.[1] || '';
		} else if (userAgent.includes('Safari')) {
			browserName = 'Apple Safari';
			browserVersion = userAgent.match(/Version\/([\d.]+)/)?.[1] || '';
		}

		return `${browserName} ${browserVersion}`;
	}

	async getCurrentLocation() {
		const [err, position] = await to(new Promise<GeolocationPosition>((resolve, reject) => {
			if (navigator.geolocation) {
			  navigator.geolocation.getCurrentPosition(resolve, reject);
			} else {
			  reject(new Error('Geolocation is not supported by this browser.'));
			}
		}));

		if (err) {
			return '';
		}

		const { latitude, longitude } = position.coords;

		return this.isUSCoordinates(latitude, longitude) ? 'US' : 'Not US';
	}

	isUSCoordinates(latitude, longitude) {
		const US_LAT_MIN = 24.396308;
		const US_LAT_MAX = 49.384358;
		const US_LON_MIN = -125.0;
		const US_LON_MAX = -66.93457;

		if (latitude >= US_LAT_MIN && latitude <= US_LAT_MAX && longitude >= US_LON_MIN && longitude <= US_LON_MAX) {
			return true;
		}

		return false;
	}
}
