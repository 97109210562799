<section>
  <div class="logo">
    <img src="/assets/img/logos/wwm_white.png" />
  </div>
  <div class="underlay"></div>
  <div class="content" *ngIf="!showForm2">
    <h3>Forgot Password</h3>
    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit($event)">
      <app-form-input [input]="formComponents.email" [form]="form"></app-form-input>
      <div class="forgot backToLogin">
        <a routerLink="/login">Back to Login</a>
      </div>
      <div class="button-line">
        <button type="submit" class="button regular solid-blue">Send Password Reset</button>
      </div>
      <div class="signup-line">Don't have an account yet? <a routerLink="/signup">Sign Up for Free.</a></div>
    </form>
  </div>
  <div class="content" *ngIf="showForm2">
    <h3>Reset Password</h3>
    <form *ngIf="goodCode === true" class="form" [formGroup]="form2" (ngSubmit)="resetPassword($event)">
      <app-form-input [input]="formComponents2.password1" [form]="form2"></app-form-input>
      <div><input type="checkbox" (click)="togglePasswordAttribute('password1')"> Show Password</div>
      <app-form-input [input]="formComponents2.password2" [form]="form2"></app-form-input>
      <div><input type="checkbox" (click)="togglePasswordAttribute('password2')"> Show Password</div>
      <div class="match-helper" *ngIf="form2.controls['password1'].value !== form2.controls['password2'].value">The password fields must match</div>
      <div class="forgot" [ngStyle]="{'margin-top': form2.controls['password1'].value !== form2.controls['password2'].value ? 0 : '-20px'}">
        <a routerLink="/login">Back to Login</a>
      </div>
      <div class="button-line">
        <button type="submit" class="button regular">Save New Password</button>
      </div>
    </form>
    <div *ngIf="goodCode === false">
      <p>The password reset link is invalid or has expired. Please visit the forgot password page to request a new link.</p>
      <div class="button-line">
        <button type="button" class="button regular submit-reset-password" (click)="changeToReset()">Forgot Password</button>
      </div>
    </div>
  </div>
  <div class="spacer"></div>
</section>
<app-new-error-modal modalId="error-modal" [options]="errorOptions"></app-new-error-modal>
<app-success-modal [modalId]="'success-modal'" [text]="successText" [maskClose]="true"></app-success-modal>
<app-confirm-modal [modalId]="'confirm-modal'" [text]="'Your password has been changed successfully!'" 
  [buttonText]="{confirm: 'Ok'}" (confirmChoice)="handleConfirm($event)"></app-confirm-modal>
