import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { NgxFileDropModule } from 'ngx-file-drop';
import { BlockItemComponent } from './html-block/block-item/block-item.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { ExampleAdModalComponent } from './example-ad-modal/example-ad-modal.component';
import { FormInputComponent } from './form-input/form-input.component';
import { HtmlBlockComponent } from './html-block/html-block.component';
import { ModalComponent } from './modal/modal.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { PaypalComponent } from './payment-form/paypal/paypal.component';
import { PubDatePickerComponent } from './pub-date-picker/pub-date-picker.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { SwitchComponent } from './switch/switch.component';
import { SuccessModalComponent } from './success-modal/success-modal.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { NotificationDropdownComponent } from './notification-dropdown/notification-dropdown.component';
import { NotificationCardsComponent } from './notification-cards/notification-cards.component';
import { KeepHtmlPipe } from './pipes/keep-html.pipe';
import { MailToComponent } from './mail-to/mail-to.component';
import { FooterComponent } from './footer/footer.component';
import { StripeComponent } from './payment-form/stripe/stripe.component';
import { StripeFormComponent } from './payment-form/stripe-form/stripe-form.component';
import { StripeStoredCardsComponent } from './payment-form/stripe-stored-cards/stripe-stored-cards.component';
import { FileDropComponent } from './file-drop/file-drop.component';
import { CartModalComponent } from './cart-modal/cart-modal.component';
import { CartItemComponent } from './cart-item/cart-item.component';
import { UpsellModalComponent } from './upsell-modal/upsell-modal.component';
import { TabsComponent } from './tabs/tabs.component';
import { DiscountTrackerComponent } from './discounts/discount-tracker/discount-tracker.component';
import { SliderAddsComponent } from './slider-adds/slider-adds.component';
import { DiscountBlockComponent } from './discounts/discount-block/discount-block.component';
import { MembershipModalComponent } from './membership-modal/membership-modal.component';
import { CongratsModalComponent } from './congrats-modal/congrats-modal.component';
import { CancelPlanModalComponent } from './cancel-plan-modal/cancel-plan.component';
import { CardExclusiveComponent } from './exclusives/card-exclusive/card-exclusive.component';
import { UpdateCardComponent } from './update-card/update-card.component';
import { UpdateCardCodeComponent } from './update-card-code/update-card-code.component';
import { ListCardsModalComponent } from './list-cards-modal/list-cards-modal.component';
import { ListStripeCardsModalComponent } from './list-stripe-cards-modal/list-stripe-cards-modal.component';
import { AddCardComponent } from './add-card/add-card.component';
import { InputFormComponent } from './input-form/input-form.component';
import { SelectFormComponent } from './select-form/select-form.component';
import { RadioFormComponent } from './radio-form/radio-form.component';
import { CheckboxFormComponent } from './checkbox-form/checkbox-form.component';
import { TextareaFormComponent } from './textarea-form/textarea-form.component';
import { FilterComponent } from './promo-wizard/filter/filter.component';
import { AddBookComponent } from '../author-dashboard/add-book/add-book.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { PromoWizardEditBookComponent } from './promo-wizard/edit-book/edit-book.component';
import { TooltipCardComponent } from './tooltip-card/tooltip-card.component';
import { SiteAdvertDetailsModalComponent } from './promo-wizard/site-advert-details-modal/site-advert-details-modal.component';
import { ProgressStepperComponent } from './progress-stepper/progress-stepper.component';
import { SharedEditBookComponent } from './edit-book/edit-book.component';
import { BookSearchComponent } from './book-search/book-search.component';
import { NewBookComponent } from './new-book/new-book.component';
import { BookCoverComponent } from './book-cover/book-cover.component';
import { CartFeatureComponent } from './cart-feature/cart-feature.component';
import { CartPromoStackComponent } from './cart-promo-stack/cart-promo-stack.component';
import { CartSubscriptionComponent } from './cart-subscription/cart-subscription.component';
import { CartFeatureNuggetComponent } from './cart-feature-nugget/cart-feature-nugget.component';
import { CartPromoStackNuggetComponent } from './cart-promo-stack-nugget/cart-promo-stack-nugget.component';
import { CartSubscriptionNuggetComponent } from './cart-subscription-nugget/cart-subscription-nugget.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { PillBarComponent } from './pill-bar/pill-bar.component';
import { DropDownComponent } from './drop-down/drop-down.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { MemberBadgeComponent } from './member-badge/member-badge.component';
import { MemberMenuComponent } from './member-menu/member-menu.component';
import { InterstitialModalComponent } from './interstitial-modal/interstitial-modal.component';
import { PromoStacksModalComponent } from './promo-stacks-modal/promo-stacks-modal.component';
import { PromoStacksTopBarComponent } from './promo-stacks-top-bar/promo-stacks-top-bar.component';
import { MemberBenefitComponent } from './member-benefit/member-benefit.component';
import { PromoStackFiltersComponent } from './promo-stacks-filters/promo-stacks-filters.component';
import { CardResourceComponent } from './card-resource/card-resource.component';
import { GuestSidebarComponent } from './guest-sidebar/guest-sidebar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SignupLoginComponent } from './signup-login/signup-login.component';
import { LaunchNewBookWidgetComponent } from './launch-new-book-widget/launch-new-book-widget.component';
import { GrowYourEmailListWidgetComponent } from './grow-your-email-list-widget/grow-your-email-list-widget.component';
import { PromoteYourBookWidgetComponent } from './promote-your-book-widget/promote-your-book-widget.component';
import { WelcomeHeaderWidgetComponent } from './welcome-header-widget/welcome-header-widget.component';
import { SliderAddsWidgetComponent } from './slider-adds-widget/slider-adds-widget.component';
import { DiscountBlockWidgetComponent } from './discount-block-widget/discount-block-widget.component';
import { DownloadCalloutComponent } from './download-callout/download-callout.component';
import { AuthorProfileCtaComponent } from './author-profile-cta/author-profile-cta.component';
import { ConfirmModalV2Component } from './confirm-modal-v2/confirm-modal-v2.component';

import { SiteAdvertAuthorComponent } from './site-advert-author/site-advert-author.component';
import { PromotionsWidgetComponent } from './promotions-widget/promotions-widget.component';

import { DiscountMeterComponent } from './discount-meter/discount-meter.component';

import { ClickOutsideDirective } from '../core/directives/click-outside.directive';

import { PubDatePipe } from '../core/pipes/pubDate.pipe';
import { TabsAccountMenuComponent } from './tabs-account-menu/tabs-account-menu.component';
import { SimpleSwitchComponent } from './simple-switch/simple-switch.component';
import { BookCardSelectableComponent } from './book-card-selectable/book-card-selectable.component';
import { PromoStacksBannerComponent } from './promo-stacks-banner/promo-stacks-banner.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { DrawerComponent } from './drawer/drawer.component';
import { BookShelfSearchComponent } from './book-shelf-search/book-shelf-search.component';
import { RecurringPromotionComponent } from './recurring-promotion/recurring-promotion.component';
import { NewErrorModalComponent } from './new-error-modal/new-error-modal.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		NgSelectModule,
		ReactiveFormsModule,
		RouterModule,
		NgxFileDropModule,
	],
	declarations: [
		BlockItemComponent,
		ConfirmModalComponent,
		ConfirmModalV2Component,
		ToggleButtonComponent,
		FormSelectComponent,
		SwitchComponent,
		ErrorModalComponent,
		ExampleAdModalComponent,
		FormInputComponent,
		HtmlBlockComponent,
		ModalComponent,
		PaymentFormComponent,
		PaypalComponent,
		PubDatePickerComponent,
		SuccessModalComponent,
		TopBarComponent,
		NotificationDropdownComponent,
		NotificationCardsComponent,
		KeepHtmlPipe,
		MailToComponent,
		FooterComponent,
		StripeComponent,
		FileDropComponent,
		CartModalComponent,
		CartItemComponent,
		UpsellModalComponent,
		TabsComponent,
		DiscountTrackerComponent,
		SliderAddsComponent,
		DiscountBlockComponent,
		MembershipModalComponent,
		CongratsModalComponent,
		CardExclusiveComponent,
		CancelPlanModalComponent,
		UpdateCardComponent,
		UpdateCardCodeComponent,
		AddCardComponent,
		ListCardsModalComponent,
		ListStripeCardsModalComponent,
		InputFormComponent,
		SelectFormComponent,
		RadioFormComponent,
		CheckboxFormComponent,
		TextareaFormComponent,
		FilterComponent,
		PromoWizardEditBookComponent,
		AddBookComponent,
		DatepickerComponent,
		TooltipCardComponent,
		SiteAdvertDetailsModalComponent,
		ProgressStepperComponent,
		SharedEditBookComponent,
		BookSearchComponent,
		NewBookComponent,
		BookCoverComponent,
		CartFeatureComponent,
		CartSubscriptionComponent,
		CartPromoStackComponent,
		CartFeatureNuggetComponent,
		CartSubscriptionNuggetComponent,
		CartPromoStackNuggetComponent,
		ProductCardComponent,
		PillBarComponent,
		DropDownComponent,
		ToggleSwitchComponent,
		MemberBadgeComponent,
		MemberMenuComponent,
		InterstitialModalComponent,
		PromoStacksModalComponent,
		PromoStacksTopBarComponent,
		PromoStackFiltersComponent,
		MemberBenefitComponent,
		StripeStoredCardsComponent,
		StripeFormComponent,
		CardResourceComponent,
		ClickOutsideDirective,
		GuestSidebarComponent,
		SiteAdvertAuthorComponent,
		PromotionsWidgetComponent,
		DiscountMeterComponent,
		PubDatePipe,
		SidebarComponent,
		SignupLoginComponent,
		LaunchNewBookWidgetComponent,
		GrowYourEmailListWidgetComponent,
		PromoteYourBookWidgetComponent,
		WelcomeHeaderWidgetComponent,
		SliderAddsWidgetComponent,
		DiscountBlockWidgetComponent,
		DownloadCalloutComponent,
		AuthorProfileCtaComponent,
		TabsAccountMenuComponent,
		SimpleSwitchComponent,
		BookCardSelectableComponent,
		PromoStacksBannerComponent,
		ContactFormComponent,
		DrawerComponent,
		BookShelfSearchComponent,
		RecurringPromotionComponent,
		NewErrorModalComponent,
	],
	exports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		NgxFileDropModule,
		ConfirmModalComponent,
		ConfirmModalV2Component,
		ToggleButtonComponent,
		FormSelectComponent,
		ErrorModalComponent,
		ExampleAdModalComponent,
		FormInputComponent,
		HtmlBlockComponent,
		ModalComponent,
		PaymentFormComponent,
		PubDatePickerComponent,
		SuccessModalComponent,
		TopBarComponent,
		NotificationDropdownComponent,
		NotificationCardsComponent,
		KeepHtmlPipe,
		MailToComponent,
		FooterComponent,
		StripeComponent,
		UpsellModalComponent,
		FileDropComponent,
		CartModalComponent,
		CartItemComponent,
		TabsComponent,
		DiscountTrackerComponent,
		SliderAddsComponent,
		DiscountBlockComponent,
		MembershipModalComponent,
		CongratsModalComponent,
		CardExclusiveComponent,
		CancelPlanModalComponent,
		UpdateCardComponent,
		UpdateCardCodeComponent,
		AddCardComponent,
		ListCardsModalComponent,
		ListStripeCardsModalComponent,
		InputFormComponent,
		SelectFormComponent,
		RadioFormComponent,
		CheckboxFormComponent,
		TextareaFormComponent,
		FilterComponent,
		PromoWizardEditBookComponent,
		AddBookComponent,
		DatepickerComponent,
		TooltipCardComponent,
		SiteAdvertDetailsModalComponent,
		ProgressStepperComponent,
		SharedEditBookComponent,
		BookSearchComponent,
		NewBookComponent,
		BookCoverComponent,
		CartFeatureComponent,
		CartPromoStackComponent,
		CartSubscriptionComponent,
		CartFeatureNuggetComponent,
		CartSubscriptionNuggetComponent,
		CartPromoStackNuggetComponent,
		ProductCardComponent,
		PillBarComponent,
		DropDownComponent,
		ToggleSwitchComponent,
		MemberBadgeComponent,
		MemberMenuComponent,
		InterstitialModalComponent,
		PromoStacksModalComponent,
		PromoStacksTopBarComponent,
		PromoStackFiltersComponent,
		MemberBenefitComponent,
		StripeStoredCardsComponent,
		StripeFormComponent,
		CardResourceComponent,
		ClickOutsideDirective,
		GuestSidebarComponent,
		SiteAdvertAuthorComponent,
		PromotionsWidgetComponent,
		DiscountMeterComponent,
		PubDatePipe,
		SidebarComponent,
		SignupLoginComponent,
		LaunchNewBookWidgetComponent,
		GrowYourEmailListWidgetComponent,
		PromoteYourBookWidgetComponent,
		WelcomeHeaderWidgetComponent,
		SliderAddsWidgetComponent,
		DiscountBlockWidgetComponent,
		DownloadCalloutComponent,
		AuthorProfileCtaComponent,
		TabsAccountMenuComponent,
		SimpleSwitchComponent,
		BookCardSelectableComponent,
		PromoStacksBannerComponent,
		ContactFormComponent,
		DrawerComponent,
		BookShelfSearchComponent,
		RecurringPromotionComponent,
		NewErrorModalComponent,
	],
})
export class SharedModule {
}
