import { EventEmitter, Injectable, Output } from '@angular/core';
import * as moment from 'moment';
import { of, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';
import { ServerConnectionService } from '../server-connection.service';
import { SettingsService } from './settings.service';
import { CoreModule } from '../../core.module';

declare const chrome: any;

@Injectable({
	providedIn: CoreModule,
})
export class UserService {
	public me;

	@Output() userChange: EventEmitter<boolean> = new EventEmitter();

	public loggedIn = false;

	public tempUserId;

	public showAuthorProfileCTA = false;

	private author = new ReplaySubject(1);

	private user = new ReplaySubject(1);

	private localStored;

	private refresh = false;

	private _author;

	constructor(
		private scs: ServerConnectionService,
		private settingService: SettingsService,
	) {
		this.settingService.getAuthorProfileCTASetting().then(
			(value) => {
				this.showAuthorProfileCTA = value || false as boolean;
			},
		);
	}

	get author$() {
		return this.author.asObservable();
	}

	get user$() {
		return this.user.asObservable();
	}

	get role() {
		return this.me?.memberRole;
	}

	get isEmulated() {
		return this.me && this._author && this.me._id !== this._author._id;
	}

	public resetPassword(email) {
		const body = {
			email,
		};
		return this.scs.http$('POST', '/api/users/request-password-reset', null, body);
	}

	public createTempUser(userInfo) {
		return this.scs.http$('POST', '/api/users/create-temp-user', null, userInfo)
			.pipe(tap((result: any) => {
				this.tempUserId = result.user._id;
			}));
	}

	public checkCode(code, authorLink) {
		return this.scs.http$('GET', `/api/users/check-reset-request/${authorLink}/${code}`, null, null);
	}

	public sendReset(password, code, authorLink) {
		const body = {
			newPass: password,
			resetHex: code,
			authorLink,
		};
		return this.scs.http$('POST', '/api/users/reset-password', null, body);
	}

	public login(email, password) {
		const body = {
			username: email,
			password,
		};
		return this.scs.http$('POST', '/server/login', null, body);
	}

	public authorizeEditorialOwl(user, cb) {
		if (!chrome?.runtime) cb(false);

		this.scs.http$('POST', '/api/editorial/authorize', null, {}).subscribe(({ success, jwt }: any) => {
			if (success) {
				chrome?.runtime?.sendMessage('jjmjaifkekjdljbcmoihlikfahlcnlpo', { jwt }, () => cb(true));
			} else {
				cb(false);
			}
		});
	}

	public logout() {
		return this.scs.http$('POST', '/server/logout', null, null);
	}

	public saveProfile(id, values) {
		const body = {};
		for (const k in values) {
			// eslint-disable-next-line no-prototype-builtins
			if (values.hasOwnProperty(k)) {
				body[k] = values[k];
			}
		}
		return this.scs.http$('PUT', `/api/users/${id}`, null, body);
	}

	public createAndLogin(user) {
		return this.scs.http$('POST', '/api/users/register', null, user);
	}

	public fetchHashedEmail(user) {
		return user ? Md5.hashStr(user.username.trim().toLowerCase()) : '';
	}

	public fetchUser(shouldRefresh = false) {
		return new Promise((resolve, reject) => {
			if (this.me && !shouldRefresh) {
				return resolve(this.me);
			}

			return this.scs.http$('GET', '/server/checkLoggedIn', null, null).subscribe(
				(result: any) => {
					if (result.success) {
						if (result.user) {
							this.updateUser(result.user);
							this.loggedIn = true;
							return resolve(this.me);
						}
					}

					return reject();
				},
				(err) => reject(err),
			);
		});
	}

	public fetchAuthor(authorLink) {
		return this.scs.http$('GET', `/api/users/author/${authorLink}`, null, null).subscribe(
			(result: any) => {
				if (result.success) {
					this._author = result.author;
					this.author.next(result.author);
				}
			},
		);
	}

	public updateAuthor(author) {
		this._author = author;
		this.author.next(author);
	}

	public updateUser(user) {
		this.me = user;
		this.user.next(user);
	}

	public wasShownAuthorProfileCTA() {
		if (!this._author) {
			return of(null);
		}

		const lastTimeShown = new Date();

		this._author.authorProfileCTA.shown = true;

		return this.scs.http$('POST', '/api/users/author-profile-cta', null, {
			userId: this._author._id,
			lastTimeShown,
		});
	}

	public shouldShowAuthorProfileCTA() {
		if (!this.showAuthorProfileCTA) {
			return false;
		}

		if (!this._author) {
			return false;
		}

		const interstitialLastShown = this._author?.interstitialModal?.lastTimeShown || new Date();
		const interstitialIsBeingDisplayedPresently = moment(moment()).diff(interstitialLastShown, 'minutes') < 1;

		// If interstitial modal hasn't shown yet, and can show, then dont show the CTA.
		if (
			(!this._author?.interstitialModal?.shown && this.isFreeMember())
			|| (this._author?.interstitialModal?.shown && interstitialIsBeingDisplayedPresently)
		) {
			return false;
		}

		const lastTimeShown = this._author?.authorProfileCTA?.lastTimeShown;

		const wasShownOver30DaysAgo = lastTimeShown && moment(moment()).diff(lastTimeShown, 'days') > 30;

		if (this._author.authorProfileCTA.shown) {
			return wasShownOver30DaysAgo;
		}

		return true;
	}

	public changePassword(oldPassword, newPassword) {
		const body = {
			newPass: newPassword,
			newPassConfirm: newPassword,
			oldPass: oldPassword,
		};
		return this.scs.http$('POST', '/api/users/password', null, body);
	}

	public amazonLogin(request) {
		const body = {
			code: request.code,
		};
		return this.scs.http$('POST', '/api/users/amazonLogin', null, body);
	}

	public checkConfirmCode(author, code) {
		return this.scs.http$('POST', `/api/users/confirmCode/${author}/${code}`, null, null);
	}

	public resendConfirm(author) {
		return this.scs.http$('POST', `/api/users/resendConfirm/${author}`, null, null);
	}

	public storeData(data) {
		this.localStored = data;
	}

	public fetchData() {
		return this.localStored;
	}

	public barRefresh(refresh) {
		this.refresh = refresh;
		this.me = undefined;
		this.userChange.emit(this.refresh);
	}

	public getAvatars() {
		return this.scs.http$('GET', '/api/users/listAvatars', null, null);
	}

	// User role checks

	public isNonAdmin(): boolean {
		return this.me && this.me.userRole && this.me.userRole.roleAccess === 1;
	}

	public isAdmin(): boolean {
		return this.me && this.me.userRole && this.me.userRole.roleAccess === 2;
	}

	public isSuperAdmin(): boolean {
		return this.me && this.me.userRole && this.me.userRole.roleAccess === 3;
	}

	public isAdminOrSuperAdmin(): boolean {
		return this.me && this.me.userRole && (this.me.userRole.roleAccess === 2 || this.me.userRole.roleAccess === 3);
	}

	// Member role checks

	public isFreeMember(): boolean {
		return this._author && this._author.memberRole && this._author.memberRole.roleAccess === 1;
	}

	public isGoldMember(): boolean {
		return this._author && this._author.memberRole && this._author.memberRole.roleAccess === 2;
	}

	public isPlatinumMember(): boolean {
		return this._author && this._author.memberRole && this._author.memberRole.roleAccess === 3;
	}

	public isMember(): boolean {
		return this._author && this._author.memberRole && (this._author.memberRole.roleAccess === 2 || this._author.memberRole.roleAccess === 3);
	}

	public hasAlphaAccess(): boolean {
		return this._author?.alphaFeatures;
	}

	public getMemberRoleName(): string {
		if (this.me && this.me.memberRole) {
			return this.me.memberRole.roleName;
		}

		return null;
	}

	public getMemberRoleId(): string {
		if (this.me && this.me.memberRole) {
			return this.me.memberRole._id;
		}

		return null;
	}

	// Sailthru
	public getSailthruProfile(id) {
		return this.scs.http$('GET', `/api/users/sailthru/profile/${id}`, null, null);
	}
}
