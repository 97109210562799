<app-modal [maskClose]="maskClose" [id]="modalId">
  <aside class="modal confirm-modal">
    <div class="modal-body">
      <div [ngClass]="(amazonError)?'amazon-header':'header'">
        <p *ngIf="title">{{title}}</p>
        <p *ngIf="!title">Error!</p>
        <div class="button regular close" (click)="close(modalId)"><span class="icon">&#x00D7;</span></div>
      </div>
      <div class="content">
        <p *ngFor="let line of text" [innerHtml]="line | keepHtml"></p>
        <ul *ngIf="_soldOutExtra && _soldOutExtra.length">
          <li *ngFor="let extra of _soldOutExtra">{{extra}}</li>
        </ul>
      </div>
      <div [ngClass]="(amazonError)?'amazon-footer':'footer'">
        <button *ngIf="!amazonError && !soldOut && _extraButtonType !== 'object'" class="button regular solid-red" (click)="close(modalId)">
          {{title ?  'Got it.' : closeText}}
        </button>
        <button *ngIf="soldOut" class="button regular" (click)="closeAndGo(modalId)">Pick Another Date</button>
        <button *ngIf="amazonError" class="button regular" (click)="close(modalId)">Continue Entering Book Info</button>
        <a *ngIf="_extraButtons === 'forgot'" routerLink="/forgot-password"><button type="button" class="button regular">Forgot Password</button></a>
        <a *ngIf="_extraButtons === 'login'" routerLink="/login"><button type="button" class="button regular">Login</button></a>
        <div *ngIf="_extraButtons && _extraButtons.length">
          <a *ngFor="let button of _extraButtons" (click)="button.callback && button.callback()">
            <button type="button" class="button regular" [ngClass]="button.class">
              {{button.text}}
            </button>
          </a>
        </div>
      </div>
    </div>
  </aside>
  <div class="confirm-modal-background"></div>
</app-modal>
