<app-modal [maskClose]="true" [id]="modalId">
	<aside class="modal confirm-modal">
		<div class="modal-body">
			<div class="header">
				<div class="button regular close" (click)="close(modalId)"><span class="icon">&#x00D7;</span></div>
			</div>
			<div class="content-wrapper">
				<div>
					<div class="content">
						<h3 class="title">{{ options.title }}</h3>
						<p class="content-text" *ngFor="let line of options.text" [innerHtml]="line | keepHtml"></p>
						<ul class="content-text-list" *ngIf="textList && textList.length">
							<li *ngFor="let extra of textList">{{extra}}</li>
						</ul>
					</div>
					<div class="footer">
						<button *ngFor="let action of options.actions" class="button regular" 
							[ngClass]="{ 'solid-green': action.type === 'solid', 'button-text': action.type === 'text' }" 
							(click)="action.callback($event)">{{ action.text }}</button>
					</div>
				</div>
				<div class="owl-image">
					<img src="../../../assets/img/modals/error-modal/owl-reading.png" alt="">
				</div>
			</div>
		</div>
	</aside>
	<div class="confirm-modal-background"></div>
</app-modal>
