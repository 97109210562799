import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppComponent } from './app.component';

/* Core Module */
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

/* Routing Module */
import { AppRoutingModule } from './app-routing/app-routing.module';

/* Login Module */
import { LoginModule } from './login/login.module';

/* Giveaways Module */
import { GiveawaysModule } from './giveaways/giveaways.module';

import { MembershipModule } from './membership/membership.module';

/* Unattached Components */
import { LandingComponent } from './unattached/landing/landing.component';
import { ReceiptComponent } from './unattached/receipt/receipt.component';
import { OrderReceiptComponent } from './unattached/order-receipt/order-receipt.component';
import { SupportComponent } from './unattached/support/support.component';
import { TermsComponent } from './unattached/terms/terms.component';
import { ToastComponent } from './unattached/toast/toast.component';

/* Rollbar, for error tracking */
import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar';

@NgModule({
	declarations: [
		AppComponent,
		LandingComponent,
		ReceiptComponent,
		OrderReceiptComponent,
		SupportComponent,
		TermsComponent,
		ToastComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		CoreModule,
		SharedModule,
		LoginModule,
		GiveawaysModule,
		MembershipModule,
		NgSelectModule,
	],
	providers: [
		{ provide: ErrorHandler, useClass: RollbarErrorHandler },
		{ provide: RollbarService, useFactory: rollbarFactory },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
}
