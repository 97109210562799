import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';
import { SiteAdvertsService } from '../core/services/data/site-adverts.service';
import { UserForm } from './user-form';
import { InputControlService } from '../core/services/input-control.service';
import { ModalService } from '../core/services/modal.service';
import { SailthruService } from '../core/services/data/sailthru.service';
import { FeaturedBooksService } from '../core/services/data/featured-books.service';
import { GiveawayService } from '../core/services/data/giveaway.service';

@Component({
	selector: 'app-giveaways',
	templateUrl: './giveaways.component.html',
	styleUrl: './giveaways.component.scss',
})

export class GiveawaysComponent implements OnInit, OnDestroy {
	public siteAdvertId;

	public siteAdvert;

	public giveaway;

	public form: UntypedFormGroup;

	public formComponents = new UserForm();

	public stored = false;

	public joined = false;

	public joinedAuthors = [];

	public errorText;

	public loading = false;

	public features;

	public errorOptions = {
		title: 'Error!',
		text: null,
		actions: [],
	};

	public sendConfirmationEmail = true;

	private route$;

	get endDate() {
		if (!this.siteAdvert) return '';

		return moment(this.siteAdvert?.giveaway?.endDate.slice(0, 10)).format('MM / DD / YYYY');
	}

	get sailthruImage() {
		if (!this.siteAdvert) return '../../assets/img/giveaways/sailthru-icon.png';

		return this.siteAdvert?.giveaway?.sourceSite?.logoURL;
	}

	get validEndDate() {
		if (!this.siteAdvert) return false;

		const today = moment();
		const endDate = moment(this.siteAdvert?.giveaway?.endDate);

		return today <= endDate;
	}

	constructor(
		private route: ActivatedRoute,
		private sas: SiteAdvertsService,
		private ics: InputControlService,
		private ms: ModalService,
		private sts: SailthruService,
		private fs: FeaturedBooksService,
		private gis: GiveawayService,
	) { }

	async ngOnInit() {
		this.form = InputControlService.toFormGroup(this.formComponents);

		this.route$ = this.route.params.subscribe(({ siteAdvertId }) => {
			this.siteAdvertId = siteAdvertId;
			this.init();
		});
	}

	ngOnDestroy() {
		this.route$.unsubscribe();
	}

	init() {
		this.siteAdvert = null;
		if (!this.siteAdvertId) return;

		this.sas.fetchSingleSiteAdvertById(this.siteAdvertId).subscribe((result: any) => {
			if (result.success) {
				this.siteAdvert = result.siteAdvert;
			}
		});

		this.fs.getFeaturesBySiteAdvert(this.siteAdvertId).subscribe((result: any) => {
			if (result.success) {
				this.features = _.sortBy(result.features, ['emailOrder']);

				this.features.forEach((feature) => {
					this.joinedAuthors[feature._id] = false;
				});
			}
		});
	}

	submit(event) {
		event.preventDefault();
		const errors = this.ics.checkFormValid(this.form, this.formComponents);

		if (errors.hasErrors) {
			this.errorText = errors.errorText;
			this.ms.open('ac-error-modal');
			return;
		}

		this.gis.createUnaffiliated(this.form.controls.email.value, this.siteAdvertId).subscribe((result: any) => {});

		this.checkListStatuses();

		this.stored = true;
	}

	joinSailthruList() {
		if (!this.stored || this.joined) return;
		this.loading = true;

		const user = {
			name: this.form.controls.name.value,
			email: this.form.controls.email.value,
			account: this.siteAdvert?.giveaway?.sourceSite?.name,
			list: this.siteAdvert?.giveaway?.sourceSite?.sailthruListName,
			source: 'SSGGiveaway',
			targetGenres: this.siteAdvert._targetGenres.map(({ name }) => name),
			siteAdvertId: this.siteAdvertId,
		};

		this.sts.joinSailthruList(user).subscribe((result: any) => {
			if (result.success) {
				this.joined = true;
			} else {
				this.errorText = result.message;
				this.ms.open('ac-error-modal');
			}

			this.loading = false;
		});
	}

	joinAuthorsList(featureId) {
		if (!this.stored || this.joinedAuthors[featureId]) return;
		this.loading = true;

		const user = {
			name: this.form.controls.name.value,
			email: this.form.controls.email.value,
			featureId,
			source: this.siteAdvert.name,
			siteAdvertId: this.siteAdvertId,
			sourceSite: {
				_id: this.siteAdvert?.giveaway?.sourceSite._id,
				name: this.siteAdvert?.giveaway?.sourceSite.name,
				abbreviatedName: this.siteAdvert?.giveaway?.sourceSite.abbreviatedName,
			},
			sendConfirmationEmail: this.sendConfirmationEmail,
		};

		this.sendConfirmationEmail = false;

		this.gis.joinAuthorsList(user).subscribe((result: any) => {
			if (result.success) {
				this.joinedAuthors[featureId] = true;
			} else {
				this.errorOptions.text = result.message;
				this.errorOptions.actions = [{ type: 'solid', text: 'Got it', callback: () => this.ms.close('ac-error-modal') }];
				this.ms.open('ac-error-modal');
			}

			this.loading = false;
		});
	}

	async checkListStatuses() {
		const body = {
			list: this.siteAdvert?.giveaway?.sourceSite?.sailthruListName,
			email: this.form.controls.email.value,
			features: this.features.map(({ _id }) => _id),
			account: this.siteAdvert?.giveaway?.sourceSite?.name,
		};

		this.loading = true;

		this.gis.checkStatusAuthorsAndSTLists(body).subscribe((result: any) => {
			if (result.success) {
				result.joinedFeatures.forEach((featureId) => {
					this.joinedAuthors[featureId] = true;
				});

				this.joined = result.joinedSailthruList;
			}

			this.loading = false;
		});
	}

	async joinAllLists() {
		const features = this.features.map(async ({ _id }) => this.joinAuthorsList(_id));
		await Promise.all(features);

		this.joinSailthruList();
	}
}
