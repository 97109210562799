<section>
    <div class="content" *ngIf="!loading">
        <div class="alert" *ngIf="!siteAdvert">
            <p>Sorry - We couldn't find the following site advert <b>{{ siteAdvertId }}</b>.</p>
        </div>
        <div class="giveaway-box" *ngIf="siteAdvert">
            <div class="site-advert">{{ siteAdvert?.name }} Email Confirmation</div>
            <div class="text-center">
                <p *ngIf="confirmed">Your email address {{ email }} is confirmed!</p>
                <p *ngIf="!confirmed">Your email address {{ email }} is not confirmed yet!</p>
            </div>

            <button class="btn block" type="button" 
                    [style]="{ 
                        'background-color': siteAdvert?.giveaway?.sourceSite?.primaryColor, 
                        'border-color': siteAdvert?.giveaway?.sourceSite?.secondaryColor }" (click)="backToGiveaway()">Back to giveaway</button>
        </div>
    </div>
    <div *ngIf="loading">
        <p>Loading...</p>
    </div>
</section>
