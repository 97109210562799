import { Component, Input } from '@angular/core';
import { ModalService } from '../../core/services/modal.service';

@Component({
	selector: 'app-new-error-modal',
	templateUrl: './new-error-modal.component.html',
	styleUrls: ['./new-error-modal.component.scss'],
})

export class NewErrorModalComponent {
	@Input() options;

	@Input() modalId;

	@Input() textList;

	constructor(
		private ms: ModalService,
	) { }

	close(id) {
		this.ms.close(id);
	}
}
