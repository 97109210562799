import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SignupForm } from './signup-form';
import { InputControlService } from '../../core/services/input-control.service';
import { ModalService } from '../../core/services/modal.service';
import { UserService } from '../../core/services/data/user.service';
import { AnalyticsService } from '../../core/services/analytics.service';
import { RecaptchaService } from '../../core/services/recaptcha.service';

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['../login/login.component.scss', './signup.component.scss'],
})
export class SignupV2Component implements OnInit {
	public errorText;

	public formComponents = new SignupForm();

	public form: UntypedFormGroup;

	public inProgress = false;

	public extraButton;

	public reCaptchaKey = '6LeFjp4UAAAAAIENT7mO2TWQJobzLsMdj0xCMs8F';

	public errorOptions = {
		title: 'There was an error!',
		text: null,
		actions: [{
			type: 'solid',
			text: 'Got it',
			callback: () => this.ms.close('error-modal'),
		}],
	};

	private purchaserInfo;

	private imageUrl;

	private avatarImages = [];

	constructor(
		private router: Router,
		private ics: InputControlService,
		private ms: ModalService,
		private uss: UserService,
		private as: AnalyticsService,
		private rs: RecaptchaService,
	) { }

	ngOnInit() {
		this.reCaptchaKey = this.rs.key;
		this.purchaserInfo = this.uss.fetchData();

		if (this.purchaserInfo) {
			this.formComponents.firstName.value = this.purchaserInfo.firstName;
			this.formComponents.lastName.value = this.purchaserInfo.lastName;
			this.formComponents.email.value = this.purchaserInfo.email.toLowerCase();
		}

		this.form = InputControlService.toFormGroup(this.formComponents);
		this.uss.storeData(null);
		this.uss.getAvatars().subscribe((data: any) => {
			if (data.success) {
				this.avatarImages = data.listAvatars;
				this.avatarImages.shift();
			} else {
				// Handle error
			}
		});
	}

	getRandomAvatar() {
		const random = Math.floor(Math.random() * this.avatarImages.length);
		return this.avatarImages[random]?.metadata?.mediaLink;
	}

	onSubmit(event) {
		if (this.inProgress) {
			return;
		}

		const errors = this.ics.checkFormValid(this.form, this.formComponents);
		if (errors.hasErrors) {
			this.errorOptions.text = errors.errorText;
		} else {
			this.errorOptions.text = [];
		}

		if (!(<HTMLInputElement>document.getElementById('agreeTerms')).checked) {
			this.errorOptions.text.push('Please agree to the Terms and Conditions before continuing.');
		}

		if (this.errorOptions.text.length > 0) {
			this.ms.open('error-modal');
			grecaptcha.reset();

			return;
		}

		if (this.avatarImages.length) {
			this.imageUrl = this.getRandomAvatar();
		}

		const user = {
			username: this.form.value.email.toLowerCase(),
			firstName: this.form.value.firstName,
			lastName: this.form.value.lastName,
			imageUrl: this.imageUrl,
			password: this.form.value.password,
		};

		this.inProgress = true;
		this.uss.createAndLogin(user).subscribe(
			(result: any) => {
				this.inProgress = false;
				if (result.success) {
					if (result.message === 'User created, needs confirmation') {
						this.as.alias(user.username);
						this.as.trackEvent('Signup - Pre Confirmation', { type: 'Email' });
						this.as.trackGTMEvent('account_created', { type: 'email', confirmed: false });
						this.router.navigate([`/confirm-account/${result.user.authorLink}/new`]).then();
						return;
					}

					this.router.navigate([`/author-dashboard/${result.user.authorLink}`]).then();
					return;
				}

				this.errorOptions.text = [result.message];

				if (result.message === 'Username is already in use') {
					this.errorOptions.title = 'Username is already in use';
					this.errorOptions.text = [];
					// eslint-disable-next-line max-len
					this.errorOptions.text.push('This often happens if you already have an account with the same username (which is just your email address).');
					// eslint-disable-next-line max-len
					this.errorOptions.text.push('You can try to create an account with a different email address or you can login into your existing account');

					this.errorOptions.actions = [
						{
							type: 'solid',
							text: 'Try Again',
							callback: () => this.ms.close('error-modal'),
						},
						{
							type: 'text',
							text: 'Login to existing account',
							callback: () => this.router.navigate(['/login']).then(),
						},
					];
				}

				this.ms.open('error-modal');
				grecaptcha.reset();
			},
		);
	}
}
