import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServerConnectionService } from '../server-connection.service';
import { CoreModule } from '../../core.module';

import { SettingAPIResponse } from '../../../types/responses';

@Injectable({
	providedIn: CoreModule,
})
export class SettingsService {
	private cartPurchaseEnabled;

	private _settingsStore = <ReplaySubject<Object>> new ReplaySubject(1);

	private dataStore = {
		store: [],
	};

	constructor(
		private scs: ServerConnectionService,
	) {
	}

	get settingsStore$() {
		return this._settingsStore.asObservable();
	}

	public fetchCartPurchaseSetting(): Promise<boolean> {
		return new Promise((resolve) => {
			if (this.cartPurchaseEnabled !== undefined) {
				resolve(this.cartPurchaseEnabled);
			} else {
				this._getSetting('getCartSetting').subscribe(
					(result: { success: boolean; value: string}) => {
						if (result.success && result.value) {
							if (typeof result.value === 'string') {
								this.cartPurchaseEnabled = result.value.trim().toLowerCase() === 'true';
							} else {
								this.cartPurchaseEnabled = result.value;
							}
							resolve(this.cartPurchaseEnabled);
						} else {
							resolve(false);
						}
					},
				);
			}
		});
	}

	public promoWizardSetting(): Promise<boolean> {
		return this._getSettingPromise('display_promo_wizard');
	}

	public resourcesSetting(): Promise<boolean> {
		return this._getSettingPromise('display_resources');
	}

	public getLoginSettings(): void {
		this._getSetting('show_login_ab').subscribe(
			(data: SettingAPIResponse) => {
				if (data.success) {
					this.dataStore.store.push({
						name: data.setting.setting,
						value: data.setting.value === 'true',
					});
					this._settingsStore.next(this.dataStore.store);
				}
			},
		);
	}

	public getExperimentSettings(): Observable<SettingAPIResponse> {
		return this._getSetting('abtesting');
	}

	public getPaypalButton() {
		return this._getSetting('paypal_button');
	}

	public purchaseFlowStep2V2(): Promise<boolean> {
		return this._getSettingPromise('purchase_flow_step2_v2');
	}

	public priceForGoldPlatinumStep2Setting(): Promise<boolean> {
		return this._getSettingPromise('display_gold_platinum_price_column');
	}

	public priceForFreeGuestStep2Setting(): Promise<boolean> {
		return this._getSettingPromise('display_free_guest_price_column');
	}

	public getMembershipUpsellSetting(): Promise<boolean> {
		return this._getSettingPromise('display_membership_upsell');
	}

	public getCheckoutSignupLoginSetting(): Promise<boolean> {
		return this._getSettingPromise('display_checkout_signup_login');
	}

	public getAuthorProfileCTASetting(): Promise<boolean> {
		return this._getSettingPromise('display_author_profile_cta');
	}

	public getNewFeatureValidationSetting(): Promise<boolean> {
		return this._getSettingPromise('enable_new_feature_validation');
	}

	public getShowLinkOptionsSetting(): Promise<boolean> {
		return this._getSettingPromise('show_link_options');
	}

	public getRecurringPromotionsSetting() {
		return this._getSetting('recurring_features')
			.pipe(map((data) => {
				if (data.success) {
					return data.setting.value;
				}
				return 'none';
			}));
	}

	public displayPromoStacksModalForFeatures(): Promise<boolean> {
		return this._getSettingPromise('display_promo_stacks_modal_for_features');
	}

	public getMemberDiscountSettings(type: 'gold' | 'platinum'): Observable<SettingAPIResponse> {
		let target = 'gold_member_discount';

		if (type === 'platinum') {
			target = 'platinum_member_discount';
		}

		return this._getSetting(target);
	}

	public stripeCheckoutEnabled(): Promise<boolean> {
		return this._getSettingPromise('stripe_checkout_enabled');
	}

	public displayImportAuthorBooksSetting(): Promise<boolean> {
		return this._getSettingPromise('display_import_author_books');
	}

	private _getSettingPromise(settingName: string): Promise<boolean> {
		return new Promise((resolve) => this._getSetting(settingName).subscribe(
			(result: SettingAPIResponse) => this._handleSettingResponse(result, resolve),
		));
	}

	private _handleSettingResponse(result: SettingAPIResponse, resolve: Function): Promise<boolean> {
		if (result.success && result.setting && result.setting.value === 'true') {
			return resolve(true);
		}

		return resolve(false);
	}

	private _getSetting(settingName: string): Observable<any> {
		return this.scs.http$('GET', `/api/settings/${settingName}`, null, null);
	}
}
