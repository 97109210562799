import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { GiveawaysComponent } from './giveaways.component';
import { ConfirmGiveawayEmailComponent } from './confirm-giveaway-email/confirm-giveaway-email.component';

@NgModule({
	imports: [
		RouterModule,
		SharedModule,
	],
	declarations: [
		GiveawaysComponent,
		ConfirmGiveawayEmailComponent,
	],
})
export class GiveawaysModule {
}
